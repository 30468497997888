import useSWR from "swr";
import axios from "axios";

const usePartner = (id) => {
  const path = `/partner/${id}`;
  const { data: partner, error } = useSWR(path, axios.get);

  return {
    partner,
    isLoading: !error && !partner,
    isError: error,
  };
};

export default usePartner;
