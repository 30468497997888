import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const useUser = (id) => {
  const path = `/user/${id}`;
  const { data: user, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

  const updateUser = async (updatedUser) => {
    await axios.put(path, updatedUser);
    mutate(path);
    mutate("/user");
  };

  return {
    user,
    isLoading: !error && !user,
    isError: error,
    updateUser,
  };
};

export default useUser;
