import React, { useState } from "react";
import { Table, PageHeader, Input, Button, message, Tag } from "antd";
import Container from "../../../../common/components/Container/Container";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styles from "./Partners.module.scss";
import usePartners from "../../hooks/usePartners";

const columns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
];

const Partners = () => {
  const { partners, isLoading } = usePartners();
  const history = useHistory();

  return (
    <>
      <Container noPadding>
        <PageHeader
          title="Partners"
          className="site-page-header"
          extra={[
            <Link to="/partner/add">
              <Button type="primary" icon={<PlusOutlined />}>
                Create Partner
              </Button>
            </Link>,
          ]}
        />
      </Container>

      <Container>
        <Table
          rowKey={(partner) => partner.id}
          loading={isLoading}
          columns={columns}
          dataSource={partners}
          rowClassName={styles.tableRow}
          onRow={(partner) => {
            return {
              onClick: (e) => {
                e.stopPropagation();
                history.push(`/partner/${partner.id}`);
              },
            };
          }}
        />
      </Container>
    </>
  );
};

export default Partners;
