import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/competition";

const useCompetitions = () => {
  const { data: competitions, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

  const addCompetition = async (competition) => {
    const addedCompetition = await axios.post(path, competition);
    mutate(path);
    return addedCompetition;
  };

  const uploadLogo = async (image) => {
    const formData = new FormData();
    formData.append("logo", image);
    const { logoUrl } = await axios.post(`/competition/logo`, formData);
    return logoUrl;
  };

  return {
    competitions,
    isLoading: !error && !competitions,
    addCompetition,
    uploadLogo
  };
};

export default useCompetitions;
