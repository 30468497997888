import React, { useEffect } from "react";
import { Form, Input, PageHeader, Select } from "antd";
import { useParams } from "react-router-dom";
import Container from "../../../../common/components/Container/Container";
import usePartner from "../../hooks/usePartner";

const PartnerDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { partner } = usePartner(id);

  useEffect(() => {
    if (partner) {
      form.setFieldsValue({
        name: partner.name,
        integrationUser: partner.integrationUser,
        description: partner.description,
        learnMoreUrl: partner.learnMoreUrl,
      });
    }
  }, [partner]);

  return (
    <>
      <Container noPadding>
        <PageHeader className="site-page-header" title={"Partner Detail"} />
      </Container>
      <Container>
        <Form
          form={form}
          name="editUser"
          requiredMark={true}
          layout={"vertical"}
          scrollToFirstError
        >
          <Form.Item name="name" label="Name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="integrationUser" label="Integration User">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="learnMoreUrl" label="Learn More URL">
            <Input disabled={true} />
          </Form.Item>
        </Form>
      </Container>
    </>
  );
};

export default PartnerDetail;
