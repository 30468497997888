import React, { useState } from "react";
import { Table, PageHeader, Input, Button, message, Tag } from "antd";
import Container from "../../../../common/components/Container/Container";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import useCompetitions from "../../hooks/useCompetitions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styles from "./Competition.module.scss";

const columns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (status) => {
      let color = null;
      if (status === "Active") color = "green";
      else if (status === "Upcoming") color = "blue";
      return <Tag color={color}>{status}</Tag>;
    },
  },
];

const Competitions = () => {
  const { competitions, isLoading } = useCompetitions();
  const history = useHistory();

  return (
    <>
      <Container noPadding>
        <PageHeader
          title="Competitions"
          className="site-page-header"
          extra={[
            <Link to="/competitions/add">
              <Button type="primary" icon={<PlusOutlined />}>
                Create Competition
              </Button>
            </Link>,
          ]}
        />
      </Container>

      <Container>
        <Table
          rowKey={(competition) => competition.id}
          loading={isLoading}
          columns={columns}
          dataSource={competitions}
          rowClassName={styles.tableRow}
          onRow={(competition) => {
            return {
              onClick: (e) => {
                e.stopPropagation();
                history.push(`/competitions/${competition.id}`);
              },
            };
          }}
        />
      </Container>
    </>
  );
};

export default Competitions;
