import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Space,
  PageHeader,
  InputNumber,
  message,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import waitTime from "../../../../common/utils/waitTime";
import { useParams } from "react-router-dom";
import Container from "../../../../common/components/Container/Container";
import useCompetition from "../../hooks/useCompetition";
import dayjs from "dayjs";

const { Option } = Select;

const submissionTypes = {
  result: "Result",
  report: "Report",
};

const CompetitionDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { competition } = useCompetition(id);
  const [submissionType, setSubmissionType] = useState(submissionTypes.report);

  useEffect(() => {
    if (competition) {
      const submissionType = competition.submissionType;
      const fields = {
        name: competition.name,
        submissionType,
        status: competition.status,
        sex: competition.sex,
        sponsorshipName: competition.sponsorshipName,
        sponsorshipDescription: competition.sponsorshipDescription,
        zipCode: competition.zipCode,
        startDate: dayjs
          .utc(competition.startDate)
          .local()
          .format("MM/DD/YYYY h:mm A"),
        endDate: dayjs
          .utc(competition.endDate)
          .local()
          .format("MM/DD/YYYY h:mm A"),
      };

      if (submissionType === submissionTypes.result) {
        const units = competition.isMetric ? "Metric" : "Imperial";

        fields.eventType = competition.eventTypeDisplayName;
        fields.distance = competition.distance;
        fields.isMetric = units;
      }

      setSubmissionType(submissionType);

      form.setFieldsValue(fields);
    }
  }, [competition]);

  let ResultBasedFormComponents = null;
  if (submissionType === submissionTypes.result)
    ResultBasedFormComponents = (
      <>
        <Form.Item name="eventType" label="Event Type">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item name="isMetric" label="Units">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item name="distance" label="Distance">
          <Input disabled={true} />
        </Form.Item>
      </>
    );

  return (
    <>
      <Container noPadding>
        <PageHeader className="site-page-header" title={"Competition Detail"} />
      </Container>
      <Container>
        <Form
          form={form}
          name="editUser"
          requiredMark={true}
          layout={"vertical"}
          scrollToFirstError
        >
          <Form.Item name="name" label="Name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="submissionType" label="Submmission Type">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="sex" label="Sex">
            <Input disabled={true} />
          </Form.Item>
          {ResultBasedFormComponents}
          <Form.Item name="status" label="Status">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="startDate" label="Start Date">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="endDate" label="End Date">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="zipCode" label="ZIP Code">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="sponsorshipName" label="Sponsorship Name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            name="sponsorshipDescription"
            label="Sponsorship Description"
          >
            <Input disabled={true} />
          </Form.Item>
        </Form>
      </Container>
    </>
  );
};

export default CompetitionDetail;
