import useSWR from "swr";
import axios from "axios";

const useCompetition = (id) => {
  const path = `/competition/${id}`;
  const { data: competition, error } = useSWR(path, axios.get);

  return {
    competition,
    isLoading: !error && !competition,
    isError: error,
  };
};

export default useCompetition;
