import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const path = "/partner";

const usePartners = () => {
  const { data: partners, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

  const addPartner = async (partner) => {
    const addedPartner = await axios.post(path, partner);
    mutate(path);
    return addedPartner;
  };

  const uploadLogo = async (image) => {
    const formData = new FormData();
    formData.append("logo", image);
    const { logoUrl } = await axios.post(`/partner/logo`, formData);
    return logoUrl;
  };

  return {
    partners,
    isLoading: !error && !partners,
    addPartner,
    uploadLogo,
  };
};

export default usePartners;
